import React from "react";
import ContactForm from "../component/Contactform";


const Contactpage = () => {
  return (
    <>
      <ContactForm  />

    </>
  );
};

export default Contactpage;
